import React, { Component } from "react"
import Title from "./Title"
import styled from "styled-components"
import { styles } from "../../globals"

export default class Article extends Component {
  render() {
    const { children, title } = this.props
    return (
      <ArticleWrapper>
        <Title title={title} className="title" />
        <div className="text" dangerouslySetInnerHTML={{ __html: children }} />
      </ArticleWrapper>
    )
  }
}

Article.defaultProps = {
  text: "Enter text",
}

const ArticleWrapper = styled.article`
  h3 {
    margin: 3rem 0 2rem 0;
  }
  table {
    font-size: 1.2rem;
    margin: 3rem 0;
  }
  ul {
    margin: 1rem 0 2rem 0;
    padding-left: 1.5rem;
  }
  width: 90%;
  margin: 0.5rem auto;
  .text {
    font-size: 1rem;
    line-height: 1.6rem;
    color: grey;
    ${styles.letterSpacing({ spacing: ".1rem" })};
  }

  @media (min-width: ${styles.smallScreenBreakpoint}) {
    width: 70%;
    .text {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
  }

  @media (min-width: ${styles.mediumScreenBreakpoint}) {
    width: 60%;
    .text {
      font-size: 1.4rem;
    }
  }
`
